#crowdin-language-picker .cr-picker-button,
#crowdin-language-picker .cr-picker-submenu {
  background-color: var(--color-code-background) !important;
  border: 1px solid var(--color-foreground-border) !important;
  color: var(--color-code-foreground) !important;
}

#crowdin-language-picker .cr-picker-button:hover,
#crowdin-language-picker .cr-picker-submenu > a:hover {
  background-color: var(--color-code-foreground) !important;
  color: var(--color-code-background) !important;
}

#crowdin-language-picker .cr-picker-submenu > a {
  color: var(--color-code-foreground) !important;
}

#crowdin-language-picker .cr-picker-submenu > a.cr-selected {
  color: var(--color-background-item) !important;
}
